<template>
  <b-card>
    <b-row>
      <b-col
        v-for="tool in tools"
        :key="tool.title"
        sm="6"
      >
        <div
          class="d-sm-flex align-center pb-2"
        >
          <div class="text-center">
            <b-img
              :src="tool.img"
              alt="Image"
              class="rounded-0"
            />
          </div>
          <div>
            <b-card-body>
              <router-link :to="{name:'forexModule-tool',params:{name:(tool.title.toLowerCase().replaceAll(' ','-')).replace(/-./g, x=>x[1].toUpperCase())}}">
                <b-card-title>
                  <span v-if="$store.state.appConfig.layout.isRTL">{{ $t('calculator') }} </span>
                  <span>{{ $t(tool.title.toLowerCase().replaceAll(' ','-').replace(/-./g, x=>x[1].toUpperCase())) }}</span>
                  <span v-if="!$store.state.appConfig.layout.isRTL"> {{ $t('calculator') }}</span>
                </b-card-title>
              </router-link>
              <b-card-text>
                {{ tool.subtitle }}
              </b-card-text>
            </b-card-body>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>

import {
  BCard, BCol, BRow, BImg, BCardBody, BCardText, BCardTitle,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
  },
  data() {
    return {
      tools: [
        {
          img: 'https://calc.fxpcm.net/images/pip.webp',
          title: 'Pip',
          subtitle: 'Accurate pip value calculator covering all relevant instruments and asset classes.',
        },
        {
          img: 'https://calc.fxpcm.net/images/LotSize.webp',
          title: 'Lot Size',
          subtitle: 'Lot size and position size risk calculator to calculate the recommended units or lot size to risk, using live market quotes, account equity, risk percentage and stop loss.',
        },
        {
          img: 'https://calc.fxpcm.net/images/Rebats.webp',
          title: 'Forex Rebate',
          subtitle: 'Use our Forex Rebates Calculator to accurately calculate how much can you earn in cashback from your trading positions with your favourite broker.',
        },
        {
          img: 'https://calc.fxpcm.net/images/Profit.webp',
          title: 'Profit',
          subtitle: 'A Profit Calculator to calculate the profit or loss value in money and pips of a trading position using live market data, trade direction and lots traded.',
        },
        {
          img: 'https://calc.fxpcm.net/images/componding.webp',
          title: 'Compounding',
          subtitle: 'Use our Compounding Calculator to accurately simulate how a trading account can grow over time with a chosen gain percentage per trade.',
        },
        {
          img: 'https://calc.fxpcm.net/images/Drawdown.webp',
          title: 'Drawdown',
          subtitle: 'Use our drawdown calculator to accurately calculate how your trading account equity can be affected after a series of losing trades.',
        },
        {
          img: 'https://calc.fxpcm.net/images/RiskOfRuin.webp',
          title: 'Risk of Ruin',
          subtitle: 'Calculate the peak-to-valley drawdown and the probability of reaching the maximal drawdown based on the win/loss rate and risk percentage of a trading system.',
        },
        {
          img: 'https://calc.fxpcm.net/images/PivotPoint.webp',
          title: 'Pivot Point',
          subtitle: 'Accurate Pivot Point Calculator to know exactly what will be the next day pivot level and the three levels of support and resistance of an instrument.',
        },
        {
          img: 'https://calc.fxpcm.net/images/Fibonacci.webp',
          title: 'Fibonacci',
          subtitle: 'Use this accurate Fibonacci levels calculator to quickly plot the hypothetical Fibonacci retracement or extension levels of several financial instrument, including cryptos and indices.',
        },
        {
          img: 'https://calc.fxpcm.net/images/Margin.webp',
          title: 'Margin',
          subtitle: 'Forex & Crypto Margin & Leverage Calculator: Calculate accurately the amount of funds used to open a trade based on lot size and the leverage offered by your broker.',
        },
      ],
    }
  },
}
</script>
